<template>
  <client-only>
    <recaptcha :id="String(id)"></recaptcha>
  </client-only>
</template>

<script lang="ts" setup>
interface IProps {
  id: string
};
const props = withDefaults(defineProps<IProps>(), {
  id: ''
});

onMounted(async () => {
  const nuxtApp: any = useNuxtApp();
  await nuxtApp.$recaptcha.init();
  const runtimeConfig = useRuntimeConfig().public;

  nuxtApp.$recaptcha._grecaptcha.render(props.id, {
    sitekey: runtimeConfig?.grecaptcha?.siteKey
  });
});
</script>

<style scoped lang="scss">

</style>
